<template>
  <div class="course">
    <div class="header">
      <img :src="require('@/assets/images/course/header-img.svg')" alt="header-img" class="header-img">
      <h1 class="dark">Page x Page is a systems-driven <br>
        process to doing better research</h1>
    </div>
    <div class="solution-block">
      <p class="research-text">Research is by its nature unpredictable. <br>
        Given that research is the systematic investigation into and study of materials and sources <br>
        in order to establish facts and reach new conclusions this is to be expected.
      </p>
      <p class="research-text research-text-after">

        Many students struggle with the complexities and uncertainties inherent in the research process <br>
        leading to stress, overwhelm, and despair at their lack of progress 💔 </p>
      <h2 class="dark">Page x Page provides a solution to this common problem</h2>
      <div class="unstuck-block">
        <div class="left">
          <img :src="require('@/assets/images/course/solution-img.png')">
          <img :src="require('@/assets/images/banging.gif')" class="unstuck-block__stickman">
        </div>
        <div class="right">
          <h2>GETTING UNSTUCK</h2>
          <p class="unstuck-text">
            <span>Adapting Design Thinking and Lean Startup principles and methodologies to academic research, Page x Page provides a roadmap and framework</span>
            to assist students
            achieve their research goals faster and more efficiently
            saving them time, effort, and needless distress so that
            they can produce their best work on time and to a high
            standard. </p>
          <p>
            Goodbye to 8+ years of PhD agony or 50%+ drop out
            rates. We believe that if you are good enough to get
            accepted into a PhD program, you are good enough to
            finish it given the right support and mentoring.
          </p>
        </div>
      </div>
    </div>
    <div class="video-block">
      <h2>Page X Page App is even better with our </h2><div class="green-italic"> Udemy course </div>
      <p class="white-text">Meet Alexander O’Hara, PhD, Page X Page founder</p>
      <div class="video">
       <!-- <img :src="require('@/assets/images/course/course.png')" alt="video" class="video__preview"> -->
        <video @click="playVideo" class="video__player" :poster="require('@/assets/images/course/course.png')">
          <source src="https://pagexpage.com/videos/ThePhDQuest.mp4" type="video/mp4">
        </video>
        <a href="https://www.udemy.com/course/master-your-phd/" target="_blank" >
          <button class="green">Check out the course on Udemy</button>
        </a>
      </div>
      <div class="background-images">
        <img :src="require('@/assets/images/course/thoughts.svg')" alt="thoughts" class="left">
        <div class="right">
          <img :src="require('@/assets/images/course/arrow-1.svg')" alt="arrow" class="arrow">
          <img :src="require('@/assets/images/course/arrow-2.svg')" alt="arrow" class="arrow">
          <img :src="require('@/assets/images/course/arrow-3.svg')" alt="arrow" class="arrow">
        </div>
      </div>
    </div>
    <div class="works-block">
      <h2 class="black">How it all works?</h2>
      <div class="content">
        <h3 class="bold">Every research project whether it is a PhD project or other can be broken into four key stages.
        </h3>
        <h3>
          Through each stage regular feedback and guidance can be <br>
          provided by the student’s supervisor(s), peers, or research coach.
        </h3>
        <div class="stages">
          <div class="stage">
            <h4>The first stage</h4>
            <p><span>The Incubation or Ideation stage.</span> This involves immersion and deep knowledge in your field. A baseline level of familiarity first needs to be established from which new
              insights and ideas can emerge. You can’t have insights about things you don’t know anything about. </p>
          </div>
          <div class="stage">
            <h4>The second stage</h4>
            <p><span>Now it is planning and establishing the parameters of your research project.</span>
              The Research Canvas tool provides a one-page overview of the research project by breaking it down into its key components. This helps the researcher to get an overview of the research project while systematically thinking through each area of their research project.
            </p>
            <p>

              Multiple versions of the Research Canvas can be quickly created to capture different research proposals before determining on one version to pursue. You no longer need
              to spend time and energy on writing a lengthy Research Proposal.
            </p>
            <p>

              The Research Canvas also helps to clarify the riskiest areas in the research project so that the researcher can tackle these issues first. Questions relating to research
              topic/supervisor fit, sources of funding, research gap and state of art in the field, relevance and potential significance of the research project can be tackled at
              this stage.
            </p>
            <p>

              The Research Roadmap provides a visual timeline with key milestones for the completion of the project that is automatically updated as the research project
              advances and can be modified by the researcher.    </p>
          </div>
          <div class="stage">
            <h4>The third stage</h4>
            <p><span>The third stage is the execution phase where the principal research work is conducted.</span> This is where the avenues of inquiry and the key research questions established in the second stage are investigated leading to new data and learning outcomes that informs the continuing research process.
            </p>
            <p>

              Guided by the key research questions established in stage 2, the researcher determines the next steps in the Goals feature as well as identifying potential obstacles to their completion within a three-month timeframe (90 days).
            </p>
            <p>
              These Goals are in turn broken down into more manageable concrete tasks that are implemented through a Kanban Board feature in Research Sprints, two to four-week sprints focused on deep dives into the research questions and on advancing learning outcomes.  </p>
            <img :src="require('@/assets/images/course/stage-img.png')" alt="ideas">
            <p>
              Research Sprints facilitate the speed at which the researcher progresses through the Knowledge Loop driving the research project forward.
            </p>
            <p>
              Research Sprints in turn inform the day-to-day tasks of the researcher helping them to stay focused while building flow and proven productivity habits into their workflow practices.
            </p>
            <p>
              Through a systematised process of constant learning and feedback at the right time which in turns informs the right action, the researcher is led to the fourth and final stage.
            </p>
          </div>
          <div class="stage">
            <h4>The fourth stage</h4>
            <p><span>The presentation of research results either through publication or through examination.</span> The results are subjected to a peer-review process leading to further revisions or to the successful completion of the research project.
            </p>
            <p>
              At each step of the research journey Page x Page provides a proven process-driven system for achieving research results while providing touch points for the supervisor and other mentors to easily guide the research path of their students.
            </p>
            <p>
              Page x Page empowers scholars to achieve their research goals while advancing the future of science and innovation.  </p>
          </div>
        </div>
        <a :href="loadDetailedSystem" download><button class="green download">Download a detailed system overview</button></a>
        <p>Free PDF, 4 pages, every stage of research carefully explained</p>
      </div>
      <!--
      <div class="footer-logo">
        <img :src="require('@/assets/images/course/footer-logo.png')" alt="logo">
      </div>-->
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue"
export default {
  name: "Course",
  props: {
    workspaceId: String
  },
  setup () {
    const loadDetailedSystem = "../../pdf/pxp-overview.pdf";

    const playVideo = function (target) {
      target.currentTarget.setAttribute("controls", true);
    }


    return {
      loadDetailedSystem,
      playVideo
    }
  }
}
</script>

<style scoped lang="sass">
@import "~@/assets/styles/color-scheme.scss"

button.green
  background: #0CAC60
  border-radius: 40px
  padding: 10px
  color: $pxp-white-color
  border: none
  cursor: pointer
.header
  background: url('~@/assets/images/course/header-background.svg')
  padding: 100px 0
  h1
    margin-top: -50px
    font-size: 48px
    line-height: 57px
.solution-block
  padding: 60px 0
  background-color: $pxp-white-color
  .research-text
    font-size: 22px
    line-height: 33px
    &:first-child
      margin-bottom: 60px
    &-after
      padding-bottom: 50px
      margin-bottom: 50px
      position: relative
      &::after
        content: ''
        width: 48px
        height: 1px
        position: absolute
        left: 50%
        bottom: 0
        margin-left: -24px
        background: #BFC4D6
  h2.dark
    font-size: 32px
    line-height: 38px
    margin-bottom: 50px
  .unstuck-block
    width: 1200px
    margin: 0 auto
    display: flex
    background: #FFF0C9
    box-shadow: 0px 4px 12px #E2DDCE
    border-radius: 8px
    position: relative
    .unstuck-block__stickman
      position: absolute
      left: 175px
      top: 110px
      z-index: 100
      width: 300px
      transform: rotate(4deg)
    .left
      width: 50%
      background: url('~@/assets/images/course/solution-background.jpg')
    .right
      width: 50%
      padding: 40px 60px 0 50px
      text-align: left
      h2
        font-size: 22px
        line-height: 150%
        margin-bottom: 16px
        color: $pxp-blue-added-color
        font-weight: 600
      p
        font-size: 18px
        margin-bottom: 20px
        font-weight: 600
        span
          color: $pxp-blue-added-color
.video-block
  padding: 60px 0 70px
  position: relative
  background: #13246E

  h2, .green-italic
    font-size: 32px
    line-height: 38px
    font-weight: 400
    color: $pxp-white-color
    margin-bottom: 20px
    display: inline-block
  .green-italic
    background: #0CAC60
    transform: skewX(-15deg)
    margin-left: 15px
  p
    font-weight: bold
    font-size: 22px
    line-height: 26px
    margin-bottom: 40px
  .video
    position: relative
    z-index: 10
    .video__player
      width: 480px
      cursor: pointer
      border-radius: 40px
      &:focus, &:active
        border: none
        outline: none
    .video__preview
      display: none
    a
      position: absolute
      bottom: -15px
      left: 50%
      margin-left: -105px
  .background-images
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 215px
    left: 0
    z-index: 0
    .right
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: space-between
      height: 125px
      margin-left: 250px
.works-block
  background: $pxp-gray-added-color
  padding: 108px 80px 100px
  h2
    font-size: 32px
    line-height: 38px
    margin-bottom: 50px
    position: relative
    &::after
      content: ''
      width: 48px
      height: 1px
      position: absolute
      left: 50%
      top: -50px
      margin-left: -24px
      background: #BFC4D6
  .content
    background-color: $pxp-white-color
    padding: 65px 0 75px
    margin-bottom: 80px
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05)
    border-radius: 8px
    h3
      font-size: 22px
      line-height: 26px
      color: $pxp-black-color
      margin-bottom: 65px
      &.bold
        font-weight: bold
    .stages
      max-width: 870px
      margin: 0 auto
      text-align: left
      position: relative
      &::before
        content: ''
        width: 2px
        height: 100%
        position: absolute
        left: 16px
        top: 0
        background: #E3E5EC
      .stage
        margin-bottom: 50px
        position: relative
        padding: 0 80px
        &::before
          content: '1'
          width: 32px
          height: 32px
          position: absolute
          top: -10px
          left: 0
          font-size: 22px
          color: #0CAC60
          border: 1px solid #0CAC60
          border-radius: 50%
          background: $pxp-white-color
          text-align: center
        h4
          font-weight: 600
          font-size: 22px
          color: #0CAC60
          margin-bottom: 25px
        p
          font-size: 18px
          margin-bottom: 20px
          span
            color: $pxp-blue-added-color
            font-weight: 600
        img
          margin-bottom: 60px
      .stage
        &:nth-child(2)
          &::before
            content: '2'
        &:nth-child(3)
          &::before
            content: '3'
        &:nth-child(4)
          &::before
            content: '4'
    button
      margin-bottom: 25px
      padding: 10px 20px
    p
      font-size: 12px
</style>
